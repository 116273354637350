<!--  -->
<template>
  <div class="user-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '我的',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="top-wrapper">
      <div class="top-box" @click="jumpPersonal">
        <div class="img-box">
          <!-- :src="userInfo ? userInfo.avatarUrl : userIcon" -->
          <van-image
            round
            width="1.5rem"
            height="1.5rem"
            :src="
              userInfo && userInfo.avatarUrl ? userInfo.avatarUrl : userIcon
            "
            fit="cover"
            class="img"
          />
          <!-- <van-icon class="sex" name="refund-o" /> -->
        </div>
        <p class="name">
          {{ userInfo ? userInfo.userName : "xxx" }}&nbsp;
          <van-icon name="arrow" class="icon-arrow" />
        </p>
        <p class="cmpy-box">
          <span class="cmpy-name"
            >{{
              userInfo ? userInfo.departmentName.replaceAll(",", "/") : ""
            }}-{{ userInfo ? userInfo.staffDuty : "" }}</span
          >
        </p>
      </div>
    </div>
    <div class="bottom-wrapper">
      <van-list
        v-if="userInfo"
        class="list"
        v-model="loading"
        @load="getUserInfo"
        :finished="finished"
      >
        <van-cell class="item" @click="handleItem('changePass')">
          <p class="left">
            <van-image
              width=".4rem"
              height=".4rem"
              fit="cover"
              :src="require('@/assets/images/setting/pass.png')"
            />
            <span class="title">修改密码</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleItem('changePhone')">
          <p class="left">
            <van-image
              width=".4rem"
              height=".4rem"
              fit="cover"
              :src="require('@/assets/images/setting/phone.png')"
            />
            <span class="title">修改手机号</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>

        <van-cell class="item" @click="handleItem('questionList')">
          <p class="left">
            <van-icon name="font-o" size="20" />
            <span class="title">调查问卷</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleItem('salaryList')">
          <p class="left">
            <van-icon name="bill-o" size="20" />
            <span class="title">工资单查询</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleItem('assetsList')">
          <p class="left">
            <van-icon name="label-o" size="20" />
            <span class="title">固定资产</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleItem('feedbackList')">
          <p class="left">
            <van-icon name="records" size="20" />
            <span class="title">用户反馈</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="goBindWechat" v-if="isWeixin">
          <p class="left">
            <van-icon name="shrink" size="20" />
            <span class="title">微信绑定</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleExit">
          <p class="left">
            <van-icon name="manager-o" size="20" />
            <span class="title">退出登录</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleItem('delCount')">
          <p class="left">
            <van-icon name="manager-o" size="20" />
            <span class="title">注销账号</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        <van-cell class="item" @click="handleItem('about')">
          <p class="left">
            <van-image
              width=".4rem"
              height=".4rem"
              fit="cover"
              :src="require('@/assets/images/setting/feedBcak.png')"
            />
            <span class="title">关于</span>
          </p>
          <p class="icon"><van-icon name="arrow" size="14" /></p>
        </van-cell>
        
      </van-list>
    </div>

    <payrollPass
      :dialogPayroll.sync="dialogPayroll"
      v-if="dialogPayroll"
      @updatePayroll="updatePayroll"
    />
    <TabBar :active.sync="activeName" @updateActive="updateActive" />
  </div>
</template>

<script>
import payrollPass from "@/components/payrollPass/index.vue";
import { getMyStatffInfo } from "@/api/setting/index.js";
import { Toast } from "vant";
import TabBar from "@/components/tabBar/index.vue";
import Cookies from "js-cookie";
import TopWrapper from "@/components/topWrapper/index.vue";
import { isWeixin } from "@/utils/isWeixin.js";

export default {
  data() {
    return {
      isWeixin,
      dialogPayroll: false,
      password: "",
      userInfo: null,
      loading: true,
      finished: true,
      activeName: "user",
      userIcon: require("@/assets/images/header.png"),
    };
  },

  components: { TabBar, payrollPass, TopWrapper },

  computed: {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    updatePayroll(res) {
      this.dialogPayroll = res;
    },
    jumpPersonal() {
      this.$router.push(`/personalInformation`);
    },
    updateActive(name) {
      this.activeName = name;
    },
    handleItem(name) {
      this.$router.push(`/${name}`);
    },
    getUserInfo() {
      getMyStatffInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.userInfo = data;
          }
        }
      });
    },
    goBindWechat() {
      let time = new Date().getTime();
      let staffCode = localStorage.getItem("staffCode_H5");
      this.$router.push(`/h5BindWechat/${time}/${staffCode}`);
    },

    handleExit() {
      localStorage.removeItem("Authorization_OA_H5");
      localStorage.removeItem("staffCode_H5");
      localStorage.removeItem("userName_H5");
      localStorage.removeItem("openId_H5");
      this.$router.replace("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.user-page {
  width: 100%;
  min-height: 100%;
  background: #fafafa;
  position: relative;
  overflow: hidden;

  .top-wrapper {
    width: 100%;
    height: 5.23rem;
    background: linear-gradient(135deg, #0094ee 0%, #4ed0ff 100%);
    padding-bottom: 0.6rem;
    .top-box {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .img-box {
        // position: absolute;
        margin-bottom: 0.3rem;
        position: relative;
        .img {
          position: relative;
        }
        // .sex {
        //   position: absolute;
        //   right: 0.32rem;
        //   top: 0.32rem;
        //   font-size: 0.32rem;
        //   color: #fff;
        // }
      }
      .name {
        font-size: 0.34rem;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.48rem;
        .icon-arrow {
          font-size: 0.34rem;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .cmpy-box {
        width: 100%;
        margin-top: 0.1rem;
        display: flex;
        flex-direction: row;

        justify-content: center;
        .cmpy-name {
          width: 90%;
          font-size: 0.34rem;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .department-name {
          width: 33.3%;
          font-size: 0.34rem;
          font-weight: 600;
          color: #ffffff;
        }
        .staff-duty {
          // width: 33.3%;
          font-size: 0.34rem;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
  .bottom-wrapper {
    background: #fff;
    position: relative;
    width: 100%;
    border-radius: 0.8rem 0.8rem 0rem 0rem;
    top: -0.8rem;
    padding-bottom: 0.8rem;
    .list {
      position: relative;
      border-radius: 0.8rem 0.8rem 0rem 0rem;
      top: 0.2rem;
      background: transparent;
      width: 100%;
      padding: 0rem 0.2rem;
      ::v-deep .van-cell {
        height: 1.3rem;
        background: transparent;
        .van-cell__value--alone {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          line-height: 0.8rem;
        }
      }
      .van-cell::after {
        border-bottom: 0.01rem solid #e5e5e5;
      }

      .left {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
          margin-left: 0.28rem;
          font-size: 0.3rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.42rem;
        }
      }
      .icon {
        height: 0.2rem;
      }
    }
  }
}
</style>
